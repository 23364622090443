.btn-pay a {
  background-position: -591px -446px;
  width: 144px;
  height: 45px;
  background-image: url("../../static/img/kr/buttons.png");
  background-repeat: no-repeat;
  text-indent: -1px;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn-pay-disabled {
  cursor: default;
  opacity: 0.2;
}
