.popup a {
  cursor: pointer;
  display: block;
  height: 34px;
  width: 34px;
  background: url("../../static/img/kr/close_popup.png") 0px 0px no-repeat;
}

.popup li {
  list-style: none;
  line-height: 22px;
}

.popup ul {
  height: auto;
}

.popup {
  width: 684px;
}

.popup ul {
  margin: 0;
  padding: 0;
}

.popup .popup-main {
  padding: 10px 10px 10px 15px;
  border: 2px solid #9a9a9a;
  border-top: 10px solid #9a9a9a;
  position: relative;
  background-color: #fff;
}

.popup .close {
  position: absolute;
  right: 27px;
  top: 27%;
  font-size: 0px;
}
