.btn-make-another-purchase a {
  width: 212px;
  height: 40px;
  background-image: url("../../static/img/kr/make_another_purchase.png");
  background-repeat: no-repeat;
  text-indent: -1px;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}
