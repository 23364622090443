.kr-consent-agreement-content {
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  background-color: #f3f3f3;
  padding-left: 21px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.kr-consent-agreement-content label {
  color: #4d4d4d;
  font-size: 13px;
  padding-left: 5px;
  cursor: pointer;
}

.kr-consent-agreement-content-label {
  width: 100%;
  line-height: 40px;
  cursor: pointer;
}

.kr-consent-agreement-button-section {
  height: 100%;
  margin-right: 20px;
}

#checkAgree {
  height: 27px;
}
