.kr-purchase-consent-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: visible;
  font-family: "Nanum Gothic", sans-serif;
}

.kr-purchase-consent .container {
  border: none;
}
