.kr-consent-left-panel {
  width: 282px;
  min-width: 282px;
  height: 850px;
  background-color: #fff;
  border-right: 1px solid #cacaca;
}

.kr-consent-left-panel .content {
  padding-top: 30px;
  margin: 0 auto;
  width: 212px;
}

.kr-consent-left-panel .rg-logo {
  position: absolute;
  top: 673px;
}

.kr-consent-agreement-title {
  width: auto;
  height: 40px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #ee2e24;
  margin-top: 30px;
  color: #ee2e24;
  font-weight: bolder;
}

.kr-consent-summoner-name {
  font-weight: bold;
  padding-top: 12px;
  font-size: 13px;
}

.kr-consent-left-panel .rp-gift {
  font-size: 16px;
  line-height: 28px;
}

.kr-consent-left-panel .battery {
  padding: 13px 0 20px 0;
}
