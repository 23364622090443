.kr-consent-radio {
  height: 15px;
  width: 15px;
  border: none;
  background: none;
  padding: 0;
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}

.kr-consent-radio {
  width: 18%;
  float: left;
}

.kr-consent-radio label {
  font-size: 12px;
  color: #4c4c4c;
  font-weight: normal;
}

.kr-consent-month-picker {
  width: inherit;
  margin-top: 12px;
}

.kr-consent-month-picker p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 12px;
  color: #4c4c4c;
  vertical-align: middle;
}

.kr-consent-month-picker-title {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  font-weight: bold;
  line-height: 28px;
}

.kr-consent-month-picker-content {
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}
