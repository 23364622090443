body.kr {
  background-color: #ffffff;
  font-family: "Nanum Gothic", sans-serif;
  font-size: 12px;
  color: #4c4c4c;
  word-break: keep-all;
}

.kr .container {
  border-bottom: none;
}

.kr-payment-options {
  display: flex;
}

.kr-payment-options .right-side {
  background-color: #fafafa;
  height: 850px;
  width: 994px;
  padding: 30px 50px;
  position: relative;
}

.kr-payment-options h3 {
  color: #4c4c4c;
  font-size: 13px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: bold;
  letter-spacing: normal;
  text-transform: none;
}

.kr-payment-options label {
  display: inline;
  cursor: pointer;
  margin: 0;
  font-weight: normal;
}

.kr-payment-options input {
  width: auto;
  vertical-align: middle;
  height: auto;
  cursor: pointer;
}

.kr-payment-options li input[type="radio"] {
  border: none;
  background: none;
}

.kr-payment-options li {
  padding-right: 0 !important;
}

.kr-payment-options .button-row {
  margin-top: 20px;
  text-align: right;
}

@media screen and (max-width: 1280px) {
  .kr-payment-options .right-side {
    padding: 5px;
    height: auto;
    width: 855px;
  }

  .kr-payment-options .button-row {
    margin-top: 10px;
  }
}
