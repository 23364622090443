.purchase-header {
  cursor: pointer;
  height: 32px;
  color: #fff;
  padding-left: 13px;
  line-height: 30px;
  background-color: #606060;
  border-radius: 3px;
}

.purchase-header span {
  font-family: "Nanum Gothic", sans-serif;
  font-size: 12px;
}

.purchase-header a {
  text-decoration: none;
  color: #fff;
}

.purchase-header a:visited {
  color: #fff;
}
