.kr-consent-rp-agree-scroll {
  border: 1px solid #b3b3b3;
  overflow-y: scroll;
  height: 220px;
  padding: 10px 20px 10px 15px;
}

.kr-consent-rp-agree-scroll p {
  line-height: 24px;
  margin: 0;
  color: #4c4c4c;
  font-size: 13px;
}

a {
  color: #787878;
}
