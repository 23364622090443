.payment-method-row {
  margin-top: 15px;
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
}

.payment-method-row dl {
  margin: 0 7px 0 0;
}

.payment-method-row dd {
  margin-left: 7px;
}

.payment-method-row dt {
  border-top: 1px solid #bebebe;
  width: 144px;
  width: 132px;
  padding-top: 10px;
  font-weight: bold;
}

.payment-method-row ul {
  list-style: none;
}

.payment-method-tabs {
  width: 748px;
}

.payment-method-row .tab-list {
  border-bottom: 2px solid #ee2e24;
  padding-left: 0;
  height: 41px;
  margin: 0;
  display: flex;
}

.payment-method-row .tab-list-item {
  display: inline-block;
  list-style: none;
  width: 144px;
  text-align: center;
  border: 1px solid #bebebe;
  background-color: #f9f9f9;
  border-bottom: 0;
  height: 39px;
  color: #6f6f6f;
  cursor: pointer;
}

.payment-method-row .tab-list-item:not(:first-child) {
  margin-left: 7px;
}

.payment-method-row .tab-list-item span {
  margin: 10px 10px 10px -7px;
  display: block;
  font-size: 16px;
}

.payment-method-row .tab-list-active {
  background-color: white;
  border: solid #ee2e24;
  border-width: 2px 2px 0 2px;
  color: #dc0000;
  height: 41px;
  font-weight: bold;
  pointer-events: none;
}

.payment-method-row .tab-content {
  border: solid #ee2e24;
  border-width: 0 2px 2px 2px;
  border-top: 0;
  height: 170px;
  background-color: #fff;
}

.payment-method-row h3 {
  padding: 16px 25px;
  font-size: 13px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #cacaca;
  margin: 0;
}

.payment-method-row ul {
  margin: 0;
  padding: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.payment-method-row li {
  width: 146px;
  padding: 0 18px 13px 18px;
}

.payment-method-row .dcb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.payment-method-row .dcb h3 {
  border-bottom: none;
}

.payment-method-row .options {
  margin: 30px 25px;
  display: flex;
}

.payment-method-row .options * {
  vertical-align: middle;
}

.payment-method-row .misc * {
  vertical-align: middle;
}

.payment-method-row input {
  margin: 0 8px 0 0;
}

.payment-method-row .options ul {
  padding: 0;
}

.payment-method-row .options li {
  padding: 0;
  width: 82px;
}

.payment-method-row .options .subtitle {
  font-family: "Nanum Gothic", sans-serif;
  width: 78px;
  font-weight: bold;
  color: #4c4c4c;
}

.payment-method-row .additional-cards {
  padding-right: 6px;
}

.payment-method-row .additional-cards ::-webkit-scrollbar-thumb,
.payment-method-row .additional-cards ::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
  border: 1px solid #ccc;
  width: 6px;
}

.payment-method-row .additional-cards .Dropdown-control {
  color: #4c4c4c;
  padding: 2px 25px 2px 10px;
}

.payment-method-row .additional-cards.active .Dropdown-control {
  border: 1px solid #ee2e24;
}

.payment-method-row .additional-cards .Dropdown-option {
  border-bottom: solid 1px #eee;
  padding: 7px 10px;
  color: #4c4c4c;
}

.payment-method-row .additional-cards .Dropdown-arrow {
  top: 8px;
}

.payment-method-row .additional-cards .Dropdown-option.is-selected,
.payment-method-row .additional-cards .Dropdown-option:hover {
  background-color: #f1f1f1;
}

@media screen and (max-width: 1280px) {
  .payment-method-row dt {
    width: 90px;
  }

  .payment-method-row dl {
    margin: 0;
  }

  .payment-method-row {
    margin-top: 5px;
  }

  .payment-method-row .tab-content {
    height: 150px;
  }
}
