.legal-row {
  display: flex;
  margin-top: 15px;
  overflow-x: auto;
  overflow-y: auto;
}

.legal-row a {
  text-decoration: underline;
  cursor: pointer;
}

.legal-row dl {
  margin: 0 7px 0 0;
}

.legal-row dt {
  border-top: 1px solid #bebebe;
  width: 144px;
  width: 132px;
  padding-top: 10px;
  font-weight: bold;
}

.legal-row dd {
  margin-left: 7px;
}

.legal-row dt span {
  width: 86px;
  line-height: 16px;
  display: block;
}

.legal-row .notice-box {
  border: 1px solid #bebebe;
  background-color: #fff;
  height: 153px;
  width: 748px;
  border-radius: 3px;
}

.legal-row ul {
  color: #808080;
  line-height: 22px;
  padding-left: 8px;
  margin: 10px 0 0 18px;
  height: 100px;
}

.legal-row li {
  list-style: none;
}

.legal-row .controls {
  background-color: #f3f3f3;
  padding: 2px 0 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 41px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: bold;
  color: #4d4d4d;
  font-size: 13px;
}

.legal-row .controls span {
  vertical-align: middle;
}

.legal-row a {
  color: #4c4c4c;
  vertical-align: top;
}

.legal-row .checkbox {
  margin-right: 20px;
}

.legal-row input[type="checkbox"] {
  display: none;
}

.legal-row input[type="checkbox"] + label {
  display: inline-block;
  padding: 0 0 0 0px;
  background: url("../../static/img/kr/agree_disabled.png") no-repeat;
  width: 119px;
  height: 27px;
}

.legal-row input[type="checkbox"]:checked + label {
  background: url("../../static/img/kr/agree_enabled.png") no-repeat;
  width: 119px;
  height: 27px;
  display: inline-block;
}

.legal-row .popup {
  position: absolute;
  left: 31px;
  bottom: 37px;
  z-index: 55;
}

@media screen and (max-width: 1280px) {
  .legal-row dt {
    width: 90px;
  }

  .legal-row dl {
    margin: 0;
  }

  .legal-row {
    margin-top: 5px;
  }

  .legal-row .notice-box {
    height: 133px;
  }

  .legal-row ul {
    height: 80px;
  }
}
