.price-point-row {
  display: flex;
  margin-top: 15px;
  overflow-x: auto;
  overflow-y: auto;
}
.price-point-row dl {
  margin: 0 7px 0 0;
  flex-basis: 132px;
  flex-grow: 0;
}

.price-point-row dt {
  border-top: 1px solid #bebebe;
  padding-top: 10px;
  font-weight: bold;
}

.price-point-row dd {
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.price-point-row ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.price-point-row li {
  margin-left: 7px;
  flex-basis: 0;
  flex-grow: 1;
}

.price-point-row::-webkit-scrollbar {
  height: 8px;
  background-color: #c5c5c5;
}

.price-point-row::-webkit-scrollbar-thumb {
  background-color: #606060;
}

.price-point-row::-webkit-scrollbar-thumb:hover {
  background-color: #606060;
}

@media screen and (max-width: 1280px) {
  .price-point-row dl {
    flex-basis: 90px;
    margin: 0;
  }

  .price-point-row {
    margin-top: 5px;
  }
}
