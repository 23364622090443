.kr-checkout-start-modal-header {
  padding-right: 5px;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 100%;
  height: 20px;
  text-align: right;
  font-size: 20px;
}

.kr-checkout-start-background {
  z-index: 56;
  position: fixed;
  background: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
}

.kr-checkout-start-modal {
  z-index: 57;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.kr-checkout-start-modal-overlay {
  z-index: 57;
  position: fixed;
  background-color: white;
  width: 720px;
  height: 670px;
  top: 50%;
  left: 50%;
  margin-left: -215px;
  margin-top: -335px;
  border: 1px solid black;
}

.kr-checkout-start-iframe {
  z-index: 56;
  width: 100%;
  height: 100%;
  position: fixed;
  border: 0;
}

.kr-checkout-start-iframe-overlay {
  z-index: 56;
  width: 718px;
  height: 648px;
  position: fixed;
  border: 0;
}

#kr-checkout-start-form {
  display: none;
}
