.price-point {
  color: #6c6c6c;
  height: 182px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  cursor: pointer;
}

.price-point.active {
  color: #dc0000;
  border: 2px solid #dc0000;
}

.price-point.disabled {
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
  cursor: not-allowed;
}

.price-point .bonus {
  color: #aeaeae;
}

.price-point.active .bonus {
  color: #dc0000;
}

.price-point.disabled .bonus {
  color: #c4c4c4;
}

.price-point .rp {
  height: 61px;
  background-color: #fff;
}

.price-point .amount {
  font-size: 22px;
  line-height: 63px;
  font-weight: bold;
  transform: scale(0.9, 1);
  display: inline-block;
  padding-left: 5px;
}

.price-point .name {
  font-weight: bold;
  font-size: 15px;
  font-family: "Nanum Gothic", sans-serif;
}

.price-point .price {
  margin-top: 10px;
  padding-left: 12px;
  font-size: 24px;
}

.price-point .bonus-rp {
  width: 100%;
  height: 35px;
  padding-left: 5px;
  margin-bottom: 13px;
}

.price-point .bonus-amount {
  font-size: 22px;
}

.price-point .bonus-type {
  font-size: 15px;
}

.price-point .bonus-name {
  position: relative;
  width: 100%;
  padding-left: 9px;
  font-size: 17px;
}
