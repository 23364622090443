.btn-pay {
  background-position: -591px -446px;
  width: 144px;
  height: 45px;
  background-image: url("../../static/img/kr/buttons.png");
  background-repeat: no-repeat;
  text-indent: -1px;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  margin: auto;
  border: 0;
}

.btn-pay.disabled {
  cursor: not-allowed;
}

.prepaid-header {
  height: 32px;
  color: #fff;
  padding-left: 13px;
  line-height: 30px;
  background-color: #606060;
}

.prepaid-header span {
  font-family: "Nanum Gothic", sans-serif;
  font-size: 12px;
}

.kr-checkout-start-modal-overlay.prepaid {
  padding: 20px;
  border: 2px solid #9a9a9a;
  border-top: 10px solid #9a9a9a;
  height: 220px;
  margin-top: -220px;
}

.prepaid-form-header-row {
  display: flex;
  width: 100%;
}

.prepaid-form-won-image {
  width: auto;
}

.prepaid-form-payment-method-name {
  width: 70%;
  font-size: 13px;
  font-weight: bold;
  color: #9a9a9a;
  margin-left: 10px;
}

.prepaid-form-close-button {
  cursor: pointer;
  font-size: 0px;
  height: 34px;
  width: 34px;
  background: url("../../static/img/kr/close_popup.png") 0px 0px no-repeat;
}

.prepaid-form-info-row {
  margin-top: 15px;
  padding-bottom: 3px;
  border-bottom: 1px solid #9a9a9a;
}

.prepaid-form-form-row {
  margin-top: 30px;
  display: flex;
}

.prepaid-form-input-help-text {
  display: block;
  margin-bottom: 5px;
}

.prepaid-form-inputs input {
  background: none #fff !important;
  width: 60px !important;
  height: 25px;
  line-height: 25px;
  border: 1px solid #9a9a9a !important;
  color: black !important;
  text-align: center;
}

.prepaid-form-submit-button {
  height: 100%;
  width: 25%;
  padding-top: 10px;
}

.prepaid-form-input-and-help {
  width: 65%;
}
